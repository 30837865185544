.about-container {
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.about-container h2 {
    color: #333;
    text-align: center;
    margin-bottom: 16px;
}

.about-container p {
    color: #555;
    line-height: 1.6;
    text-align: justify;
}