body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

.home {
    text-align: center;
    font-family: Arial, sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.home-header,
.home-footer {
    background-color: #282c34;
    color: white;
    padding: 10px;
}

.home-header {
    min-height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.about,
.services,
.contact {
    margin: 20px;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

.home-footer {
    margin-top: auto; /* Pushes down the footer to the bottom */
}

a {
    color: #61dafb;
}

a:hover {
    color: #21a1f1;
}